import { Injectable } from '@angular/core';
import { WindowRef } from './ng';

declare global {
  interface Window {
    _paq: unknown[][];
  }
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private url: string | undefined;
  private siteId: number | undefined;
  private initializedTracking = false;
  private window = this.windowRef.nativeWindow;

  constructor(private windowRef: WindowRef) {
    this.window._paq = window._paq || [];
    this.window._paq.push(['requireCookieConsent']);
  }

  /**
   * Initialize the tracking data. Tracking starts not before enabledTracking is called.
   * @param url The URL the analytics data are sent to
   * @param siteId The ID of the site the data belongs to
   */
  initializeTracking(url: string, siteId: number): void {
    if (this.initializedTracking) {
      if (this.url != url || this.siteId != siteId) {
        alert('Analytics error: Duplicated call with different parameters');
      }
      return;
    }
    this.url = url;
    this.siteId = siteId;
    this.initializedTracking = true;
    this.executeOriginalMatomoTrackerCode(url, siteId);
  }

  /**
   * Logs an event. The event is ignored until both enableTracking and initializeTracking were called.
   * @param category
   * @param action
   * @param name
   * @param value
   */
  logEvent(category: string, action: string, name: string, value: number | undefined = undefined): void {
    this.window._paq.push(['trackEvent', category, action, name, value]);
  }

  /**
   * Allows to track analytics data. The tracking starts not before initializeTracking is called.
   */
  public setCookieConsent(allowed: boolean) {
    this.window._paq.push([allowed ? 'setCookieConsentGiven' : 'forgetCookieConsentGiven']);
    console.log('Allowed tracking:', allowed);
  }

  private executeOriginalMatomoTrackerCode(url: string, siteId: number): void {
    this.window._paq.push(['trackPageView']);

    (function (w: Window) {
      const u = `//${url}/`;
      w._paq.push(['setTrackerUrl', `${u}matomo.php`]);
      w._paq.push(['setSiteId', siteId.toString()]);
      const g = document.createElement('script');
      const s = document.getElementsByTagName('script')[0];
      g.async = true;
      g.src = `${u}matomo.js`;
      s.parentNode?.insertBefore(g, s);
    })(this.window);
  }
}
