/// <reference types="@angular/localize" />

import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app.config';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn:
    document.location.hostname.indexOf('local') >= 0 || document.location.hostname.indexOf('dev') >= 0
      ? 'https://c834c182e200c07b4c24590760b87492@o4507407783821312.ingest.de.sentry.io/4508053189427280'
      : 'https://bec91b6e43a0d4a4dfdfdc5e0c5de244@o4507407783821312.ingest.de.sentry.io/4508086013526096',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['prod', /^https:\/\/kontor-new-media-gmbh.sentry\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig).catch((err) => console.log(err));
