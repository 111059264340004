import { SnakeCasedPropertiesDeep } from 'type-fest';

export function snakeToCamel<T extends object>(t: SnakeCasedPropertiesDeep<T>): T {
  return Object.keys(t).reduce((r, k) => {
    const key = k as keyof typeof t;
    const tKey = k.replace(/_(.)/g, (_a, v) => v.toUpperCase()) as keyof T;
    if (Array.isArray(t[key]))
      return {
        ...r,
        [tKey]: (t[key] as unknown[])
          .filter((v: unknown) => !!v)
          .map((v: unknown) => snakeToCamel(v as SnakeCasedPropertiesDeep<unknown>)),
      };
    const tempKey = k as keyof typeof t;
    if (typeof t[tempKey] == 'object' && !!t[tempKey])
      return {
        ...r,
        [tKey]: snakeToCamel(t[k as keyof typeof t] as SnakeCasedPropertiesDeep<unknown>) as unknown,
      };
    return { ...r, [tKey]: t[k as keyof typeof t] as SnakeCasedPropertiesDeep<unknown> };
  }, {} as T);
}
