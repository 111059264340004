<div class="input">
  <form>
    <label [for]="control" i18n="@@Youtube.label.search">Search</label>
    <input
      [formControl]="control"
      [type]="type"
      [placeholder]="placeholder"
      [required]="required"
      matInput
      [matAutocomplete]="auto"
    />
    <svg-icon class="input-icon" [icon]="'icon_' + icon"></svg-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected.next($event)"
      class="autocomplete-panel"
      [class]="theme"
    >
      <div *ngIf="autocompleteGroups">
        <mat-optgroup *ngFor="let group of groupOptions | async" [label]="group.groupName" class="optGroup">
          <mat-option *ngFor="let name of group.options" [value]="name" class="option">{{ name }}</mat-option>
        </mat-optgroup>
      </div>
      <div *ngIf="options">
        <mat-option *ngFor="let name of obsOptions | async" [value]="name" class="option">{{ name }}</mat-option>
      </div>
    </mat-autocomplete>
    <button mat-icon-button *ngIf="getInput()" class="clearButton" (click)="clearInput()">
      <svg-icon class="clear-icon" icon="icon_close"></svg-icon>
    </button>
  </form>
</div>
