export type DmbUiEnvironmentConfig = {
  production: boolean;
  v5Port: string;
  v5Path: string;
};

export const baseUIEnv = (): DmbUiEnvironmentConfig => ({
  production: true,
  v5Port: '',
  v5Path: '/v5',
});
