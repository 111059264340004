import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { DmbUiModule } from '@dmb/dmb-ui';

import * as Sentry from '@sentry/angular';
import { AppComponent } from './app.component';
import { provideAnimations } from '@angular/platform-browser/animations';

@NgModule({
  imports: [BrowserModule, HttpClientModule, DmbUiModule, RouterOutlet, AppComponent],
  providers: [
    provideAnimations(),
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => ({}), deps: [Sentry.TraceService], multi: true },
  ],
})
export class AppModule {}
