import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutTranslation {
  readonly ['menu.search'] = $localize`:@@menu.search:Search`;
  readonly ['books'] = $localize`:@@menu.books:Ebooks`;
  readonly ['books.book.create'] = $localize`:@@menu.books.book.create:Create Ebook`;
  readonly ['books.book.search'] = $localize`:@@menu.books.book.search:Search Ebook`;
  readonly ['books.bulkDeliveries'] = $localize`:@@menu.books.bulkDeliveries:Bulk deliveries`;
  readonly ['books.deliveries'] = $localize`:@@menu.books.deliveries:Deliveries`;
  readonly ['common'] = $localize`:@@menu.common:Common Settings`;
  readonly ['common.countries'] = $localize`:@@menu.common.countries:Countries`;
  readonly ['common.genres'] = $localize`:@@menu.common.genres:Manage genres`;
  readonly ['common.keywords'] = $localize`:@@menu.common.keywords:Manage keywords`;
  readonly ['common.labels'] = $localize`:@@menu.common.labels:Manage your labels `;
  readonly ['common.manageFAQs'] = $localize`:@@menu.common.manageFAQs:Manage FAQs`;
  readonly ['common.promoSettings'] = $localize`:@@menu.common.promoSettings:Save-It settings`;
  readonly ['common.qcDenials'] = $localize`:@@menu.common.qcDenials:Manage QC decline reasons`;
  readonly ['common.regions'] = $localize`:@@menu.common.regions:Manage Regions`;
  readonly ['common.productCodeSearch'] = $localize`:@@menu.statistics.productCodeSearch:Produkt-Codes verwalten`;
  readonly ['contributors'] = $localize`:@@menu.contributors:Contributors`;
  readonly ['customers'] = $localize`:@@menu.customers:Customers`;
  readonly ['customers.clients'] = $localize`:@@menu.customers.clients:Manage clients`;
  readonly ['customers.contracts'] = $localize`:@@menu.customers.contracts:Manage contracts`;
  readonly ['customers.licensors'] = $localize`:@@menu.customers.licensors:Manage licensors`;
  readonly ['customers.news'] = $localize`:@@menu.customers.news:Manage News`;
  readonly ['customers.userGroups'] = $localize`:@@menu.customers.userGroups:Manage user groups`;
  readonly ['customers.users'] = $localize`:@@menu.customers.users:Manage users`;
  readonly ['dashboard'] = $localize`:@@menu.dashboard:Dashboard`;
  readonly ['ilas'] = $localize`:@@menu.ilas:ILAS`;
  readonly ['logout'] = $localize`:@@menu.logout:Log out`;
  readonly ['main.dmbForArtists'] = $localize`:@@menu.main.dmbForArtists:DMB for Artists`;
  readonly ['main.support'] = $localize`:@@menu.main.support:Support`;
  readonly ['main.faqs'] = $localize`:@@menu.main.faqs:FAQs`;
  readonly ['main.help'] = $localize`:@@menu.main.help:Help`;
  readonly ['main.labelManager'] = $localize`:@@menu.main.labelManager:My Label Manager`;
  readonly ['main.news'] = $localize`:@@menu.main.news:News`;
  readonly ['main.productBags'] = $localize`:@@menu.main.productBags:Product Bags`;
  readonly ['movies'] = $localize`:@@menu.movies:Movies`;
  readonly ['movies.bulkDeliveries'] = $localize`:@@menu.movies.bulkDeliveries:Bulk Deliveries`;
  readonly ['movies.deliveries'] = $localize`:@@menu.movies.deliveries:Deliveries`;
  readonly ['movies.movie.create'] = $localize`:@@menu.movies.movie.create:Create movie`;
  readonly ['movies.movie.create.wizard'] = $localize`:@@menu.movies.movie.create.wizard:Create with wizard`;
  readonly ['movies.movie.search'] = $localize`:@@menu.movies.movie.search:Search movies`;
  readonly ['music'] = $localize`:@@menu.music:Music`;
  readonly ['music.album.create'] = $localize`:@@menu.music.album.create:Create album`;
  readonly ['music.album.search'] = $localize`:@@menu.music.album.search:Search albums`;
  readonly ['music.bulkDeliveries'] = $localize`:@@menu.music.bulkDeliveries:Bulk Deliveries`;
  readonly ['music.deliveries'] = $localize`:@@menu.music.deliveries:Deliveries`;
  readonly ['music.track.create'] = $localize`:@@menu.music.track.create:Create track`;
  readonly ['music.track.search'] = $localize`:@@menu.music.track.search:Search tracks`;
  readonly ['music.videoMaker'] = $localize`:@@menu.music.videoMaker:Video-Composer`;
  readonly ['music.track.dmSearch'] = $localize`:@@menu.music.dmSearch:Spotify Discovery-Mode track`;
  readonly ['personal'] = $localize`:@@menu.personal:Personal`;
  readonly ['personal.account'] = $localize`:@@menu.personal.account:Profile`;
  readonly ['personal.company'] = $localize`:@@menu.personal.company:Company`;
  readonly ['physical'] = $localize`:@@menu.physical:Physical`;
  readonly ['physical.product.add'] = $localize`:@@menu.physical.product.add:Add product`;
  readonly ['physical.product.search'] = $localize`:@@menu.physical.product.search:Search product`;
  readonly ['physical.project.add'] = $localize`:@@menu.physical.project.add:Add project`;
  readonly ['physical.project.search'] = $localize`:@@menu.physical.project.search:Search project`;
  readonly ['physical.sales-document.search'] = $localize`:@@menu.physical.sales-document.search:Sales documents`;
  readonly ['physical.track.add'] = $localize`:@@menu.physical.track.add:Add track`;
  readonly ['physical.track.search'] = $localize`:@@menu.physical.track.search:Search tracks`;
  readonly ['pitch'] = $localize`:@@menu.pitch:Product Pitching`;
  readonly ['pitch.releaseSchedule'] = $localize`:@@menu.pitch.releaseSchedule:Release Schedule`;
  readonly ['pitch.search'] = $localize`:@@menu.pitch.search:Manage Pitches`;
  readonly ['royalties'] = $localize`:@@menu.royalties:Royalty Reports`;
  readonly ['statistics'] = $localize`:@@menu.statistics:Statistics and Analyses`;
  readonly ['statistics.accounting'] = $localize`:@@menu.statistics.accounting:Accounting`;
  readonly ['statistics.availableGenres'] = $localize`:@@menu.statistics.availableGenres:Available genres`;
  readonly ['statistics.availableRetailers'] = $localize`:@@menu.statistics.availableRetailers:Available Retailers`;
  readonly ['statistics.delivery'] = $localize`:@@menu.statistics.delivery:Delivery`;
  readonly ['statistics.encoding'] = $localize`:@@menu.statistics.encoding:Encoding`;
  readonly ['statistics.productCodeWizard'] = $localize`:@@menu.statistics.productCodeWizard:Product code wizard`;
  readonly ['statistics.productTickets'] = $localize`:@@menu.statistics.productTickets:Product Tickets`;
  readonly ['statistics.products'] = $localize`:@@menu.statistics.products:Products`;
  readonly ['statistics.qualityCheck'] = $localize`:@@menu.statistics.qualityCheck:QC`;
  readonly ['statistics.qualityCheck.report'] = $localize`:@@menu.statistics.qualityCheck.report:QC Reports`;
  readonly ['statistics.turnaround'] = $localize`:@@menu.statistics.turnaround:Turnaround`;
  readonly ['statistics.distributionNetwork'] = $localize`:@@menu.statistics.distribution-network:Distribution Network`;
  readonly ['system'] = $localize`:@@menu.system:System Settings`;
  readonly ['system.appleDigitalMasters'] = $localize`:@@menu.system.appleDigitalMasters:Apple Digital Masters`;
  readonly ['system.artistStoreIssues'] = $localize`:@@menu.system.artistStoreIssues:Artist/store issues`;
  readonly ['system.manageExternalHDs'] = $localize`:@@menu.system.manageExternalHDs:External HDs`;
  readonly ['system.manageRetailers'] = $localize`:@@menu.system.manageRetailers:Retailers`;
  readonly ['system.manageStores'] = $localize`:@@menu.system.manageStores:Stores`;
  readonly ['system.manageTrendReports'] = $localize`:@@menu.system.manageTrendReports:Trend reports`;
  readonly ['system.manualProcesses'] = $localize`:@@menu.system.manualProcesses:Manual processes`;
  readonly ['system.systemDv'] = $localize`:@@menu.system.systemDv:Domain Values`;
  readonly ['system.systemStatus'] = $localize`:@@menu.system.systemStatus:System status`;
  readonly ['trends'] = $localize`:@@menu.trends:Trend reports`;
  readonly ['trends.awards'] = $localize`:@@menu.trends.awards:Awards`;
  readonly ['trends.report.global'] = $localize`:@@menu.trends.report.global:GFK - global report`;
  readonly ['trends.report.own'] = $localize`:@@menu.trends.report.own:GFK - own repertoire (EDEL)`;
  readonly ['trends.socialNetworks.internal'] = $localize`:@@menu.trends.socialNetworks.internal:Social Networks`;
  readonly ['trends.view'] = $localize`:@@menu.trends.view:Show trend reports`;
  readonly ['trends.unmatchedLines'] = $localize`:@@menu.trends.unmatchedLines:Match IDs from trend reports`;
  readonly ['tv'] = $localize`:@@menu.tv:TV Series`;
  readonly ['tv.bulkDeliveries'] = $localize`:@@menu.tv.bulkDeliveries:Bulk Deliveries`;
  readonly ['tv.deliveries'] = $localize`:@@menu.tv.deliveries:Deliveries`;
  readonly ['tv.episodes.search'] = $localize`:@@menu.tv.episodes.search:Search episodes`;
  readonly ['tv.seasons.search'] = $localize`:@@menu.tv.seasons.search:Search seasons`;
  readonly ['tv.series.create'] = $localize`:@@menu.tv.series.create:Create series`;
  readonly ['tv.series.search'] = $localize`:@@menu.tv.series.search:Search series`;
  readonly ['unplaylisted'] = $localize`:@@Common.menu.Unplaylisted:Recently Removed Playlist Tracks`;
  readonly ['upcomingreleases'] = $localize`:@@Common.menu.UpcomingReleases:Upcoming Releases`;
  readonly ['youtube'] = $localize`:@@menu.youtube:Youtube`;
  readonly ['youtube.analytics'] = $localize`:@@menu.youtube.analytics:Analytics`;
  readonly ['youtube.management'] = $localize`:@@menu.youtube.management:Delivery & SAP`;
  readonly ['youtube.managementLegacy'] = $localize`:@@menu.youtube.managementLegacy:ILAS, legacy`;
  readonly ['youtube.videoManager'] = $localize`:@@menu.youtube.videoManager:Video Manager`;

  static getTranslations() {
    return new LayoutTranslation();
  }
}
